<template>
  <el-select
    v-model="cashRegisterModel"
    @change="cashRegisterValChanged"
    autocomplete="new-password"
    :placeholder="$t('COMMON.CASH_REGISTER')"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getCashRegisters"
    :loading="loading"
  >
    <el-option v-if="showAll" :value="null" :label="$t('COMMON.ALL_CASH_REGISTERS')">
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="cashRegister in cashRegisters"
      :key="cashRegister.id"
      :value="cashRegister.id"
      :label="`${cashRegister.name}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "cash-register-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    cashRegister: {
      type: String,
      default: null,
      description: "cash register id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterEstablishment: {
      type: String,
      default: null,
      description: "Establishment id",
    },
    filterServiceCenter: {
      type: String,
      default: null,
      description: "ServiceCenter id",
    },
    filterOperationType: {
      type: String,
      default: null,
      description: "Operation type",
    },
  },

  data() {
    return {
      cashRegisterModel: this.cashRegister,
      cashRegisters: {},
      loading: true,
    };
  },

  setup() {},

  created() {
    this.getCashRegisters(null, this.cashRegister);
  },

  methods: {
    async getCashRegisters(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
         include: "organization,establishment,serviceCenter,cafeteria",
          sort: "name",
          filter: {
            ...(query ? { search: query } : {}),
            ...(id ? { id: id } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }

        if (this.filterEstablishment) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              establishment: this.filterEstablishment,
            },
          };
        }

        if (this.filterServiceCenter) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              serviceCenter: this.filterServiceCenter,
            },
          };
        }

        if (this.filterOperationType) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              operation_type: this.filterOperationType,
            },
          };
        }

        await this.$store.dispatch("cashRegisters/list", params);
        const cashRegistersArr = await this.$store.getters["cashRegisters/list"];
        this.cashRegisters = {};
        cashRegistersArr.forEach((cashRegister) => {
          this.cashRegisters[cashRegister.id] = cashRegister;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    cashRegisterValChanged(cashRegister) {
      const cashRegisterSelected = Object.values(this.cashRegisters).find(
        (item) => item.id === cashRegister
      );
      console.log(cashRegisterSelected)
      this.$emit("cashRegisterChanged", cashRegister, cashRegisterSelected);
    },
  },

  watch: {
    cashRegister(cashRegister) {
      if (cashRegister) {
        if (cashRegister !== this.cashRegisterModel) {
          this.cashRegisterModel = cashRegister;
          this.getCashRegisters(null, cashRegister);
        }
      } else {
        this.cashRegisterModel = null;
      }
    },
    filterOrganization(filterOrganization) {
      this.getCashRegisters();
    },
    filterServiceCenter(filterServiceCenter) {
      this.getCashRegisters();
    },
    filterEstablishment(filterEstablishment) {
      this.getCashRegisters();
    },
    filterOperationType(filterEstablishment) {
      this.getCashRegisters();
    },
  },
};
</script>
